export function searchConvertObj(str) {
  let search = decodeURI(str);
  if (search.length === 0) {
    return {};
  }
  let s = search.slice(1);
  let arr = s.split('&');
  if (arr.length === 0) {
    return {};
  }
  let obj = {};
  arr.forEach((element) => {
    let arr = element.split('=');
    if (arr.length === 2) {
      obj[arr[0]] = arr[1];
    }
  });
  return obj;
}
