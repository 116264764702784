export function getConfig(departmentId) {
  switch (departmentId) {
    case 26:
    case 77:
      return {
        isShowSize: false,
        isFolb: false,
        isShowPost: true
      };
    case 80:
      return {
        isShowSize: false,
        isFolb: false,
        isShowPost: false
      };
    case 123:
      return {
        isShowSize: true,
        isFolb: true,
        isShowPost: true
      };
    case 11:
    case 12:
    case 14:
    case 15:
    case 16:
    case 17:
    case 66:
    case 72:
    case 73:
    case 74:
    case 75:
    case 76:
    case 81:
    case 82:
    case 83:
    case 89:
    case 90:
    case 91:
    case 92:
    case 93:
    case 94:
    case 95:
    case 96:
    case 97:
    case 98:
    case 99:
    case 101:
    case 1212:
      return {
        isShowSize: true,
        isFolb: false,
        isShowPost: true
      };
    case 22:
    case 25:
    case 69:
    case 78:
    case 79:
    case 84:
    case 85:
    case 86:
    case 87:
    case 100:
    case 102:
    case 103:
    case 105:
    case 106:
    default:
      return {
        isShowSize: true,
        isFolb: true,
        isShowPost: true
      };
  }
}
export function getIsFolb(departmentId) {
  switch (departmentId) {
    case 11:
    case 12:
    case 14:
    case 15:
    case 16:
    case 17:
    case 66:
    case 72:
    case 73:
    case 74:
    case 75:
    case 76:
    case 77:
    case 80:
    case 81:
    case 82:
    case 83:
    case 89:
    case 90:
    case 91:
    case 92:
    case 93:
    case 94:
    case 95:
    case 96:
    case 97:
    case 98:
    case 99:
    case 101:
      return false;
    case 22:
    case 25:
    case 69:
    case 78:
    case 79:
    case 84:
    case 85:
    case 86:
    case 87:
    case 100:
    case 102:
    case 103:
    case 105:
    case 106:
    default:
      return true;
  }
}
