import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import dva from 'dva';
import history from './config/history';
import { RouterConfig as router } from './config/router';
import { createLogger } from 'redux-logger';
import { message } from 'antd';
import './style/normalize.css';
import './style/global.css';
import './style/color.css';

// ReactDOM.render(
//   <Order />,
//   document.getElementById('root')
// );
let config = {
  history: history,
};

if (process.env.NODE_ENV === 'development') {
  config.onError = (e) => { message.error(e.message, 3); };
  config.onAction = createLogger({ collapsed: true });
}

const app = dva(config);
app.router(router);
app.start('#root');