import { fetch } from 'whatwg-fetch';
import { message } from 'antd';
import { host, cloud } from '../config/baseurl';


/**
 * 函数描述
 * @name 请求接口
 * @param {string} url 地址
 * @param {string} method 方法
 * @param {object} options body参数
 * @return {Promise} 请求结果
 */
export default async function request(url, method, options, isVoid = false) {
  let opt = options || {};
  if (!url.startsWith('http')) {
    url = encodeURI(`${host}/${url}`);
  }
  try {
    method = method || 'get';
    let response = await fetch(url, {
      method: method,
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'DateTime': new Date().toUTCString(),
        'Soft': '3.0',
        'Version': '90000-1603810769873',
        'Cloud': cloud,
        // 'Authorization': '9643c774-ce9f-471f-a3f4-54d2d2686dc0',
      },
      body: method !== 'get' ? JSON.stringify(opt) : null,
    });
    if (isVoid) {
      return;
    }
    let data = null;
    if (
      response.headers.map['content-type'] &&
      response.headers.map['content-type'].indexOf('application/json') > -1
    ) {
      data = await response.json();
    } else {
      data = await response.text();
    }
    if (response.status >= 400 && data) {
      message.error(data);
    }
    response.data = data;
    return { ...response };
  } catch (e) {
    message.error('网络问题或服务器内部发生错误');
    return {
      status: 500,
      statusText: 'server error',
      data: '网络问题或服务器内部发生错误',
    };
  }
}
